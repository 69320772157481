<template>
    <div class="mr-3  mb-3 float-left">
        <button
            class="btn btn-outline-primary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style="color: #000000;border-color: #c7c7c7;"
        >
            {{ textBtn }}
            <div class="text-ellipsis">:</div>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <label
                @click.prevent="quickFilter('sub', 1)"
                class="dropdown-item d-block pointer"
            >
                <a>
                    Yesterday - Today
                </a>
            </label>
            <label
                @click.prevent="quickFilter('add', 1)"
                class="dropdown-item d-block pointer"
            >
                <a>
                    Today - Tomorrow
                </a>
            </label>
            <label
                @click.prevent="quickFilter('sub', 7)"
                class="dropdown-item d-block pointer"
            >
                <a>
                    7 days ago - Today
                </a>
            </label>
            <label
                @click.prevent="quickFilter('add', 7)"
                class="dropdown-item d-block pointer"
            >
                <a>
                    Today - 7 days ahead
                </a>
            </label>
            <label
                @click.prevent="quickFilter('sub', 30)"
                class="dropdown-item d-block pointer"
            >
                <a>
                    30 days ago - Today
                </a>
            </label>
            <label
                @click.prevent="clearDates"
                class="dropdown-item d-block pointer"
            >
                <a>
                    Clear Dates
                </a>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        textBtn: {
            type: String,
            default: "Quick Filter Tanggal Kunjungan"
        }
    },
    methods: {
        quickFilter(op, days) {
            // let date = { start: null, end: null };
            let dateStart = null;
            let dateEnd = null;
            if (op == "sub") {
                dateStart = moment()
                    .subtract(days, "days")
                    .format("YYYY-MM-DD 00:00:00");
                dateEnd = moment().format("YYYY-MM-DD 23:59:59");
            } else if (op == "add") {
                dateEnd = moment()
                    .add(days, "days")
                    .format("YYYY-MM-DD 00:00:00");
                dateStart = moment().format("YYYY-MM-DD 23:59:59");
            }
            if (dateStart && dateEnd) {
                this.$emit("update:changedValueStart", dateStart);
                this.$emit("update:changedValueEnd", dateEnd);
            }
            // this.$emit('quickFilter', value);
        },
        clearDates() {
            this.$emit("update:changedValueStart", null);
            this.$emit("update:changedValueEnd", null);
        }
    }
};
</script>

<style></style>
