<template>
    <div class="mr-3  mb-3 float-left">
        <button
            class="btn btn-outline-primary dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title="Pilih Status Pasien Yang Dimiliki Data"
            style="color: #000000;border-color: #c7c7c7;"
        >
            Status Pasien
            <div class="text-ellipsis">:</div>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="checkAllStatus"
                    type="checkbox"
                    name="checkbox"
                    :value="true"
                    class="mt-1"
                    v-model="checkUncheck"
                />&nbsp;{{ !checkUncheck ? "CHECK ALL" : "UNCHECK ALL" }}
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="REGISTRASI_CREATED"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; REGISTRASI CREATED
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="GENERATE_QR_OFFLINE"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; GENERATE QR OFFLINE
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="GENERATE_QR_ONLINE"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; GENERATE QR ONLINE
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="WAITING_PAYMENT_ONLINE"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; WAITING PAYMENT ONLINE
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="PAYMENT_EXPIRED"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; PAYMENT EXPIRED
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="PAYMENT_CHANGE"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; PAYMENT CHANGE
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="CHANGE_PAYMENT_TO_ONLINE"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; CHANGE PAYMENT TO ONLINE
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="CHANGE_PAYMENT_TO_OFFLINE"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; CHANGE PAYMENT TO OFFLINE
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="UPDATE_PATIENT"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; UPDATE PATIENT
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="UPDATE_PAKET_PATIENT"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; UPDATE PAKET PATIENT
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    type="checkbox"
                    name="checkbox"
                    value="PATIENT_DATANG"
                    class="mt-1"
                    v-model="statusPicked"
                />&nbsp; PATIENT DATANG
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    class="mt-1"
                    type="checkbox"
                    name="checkbox"
                    value="PAID"
                    v-model="statusPicked"
                />&nbsp; PAID
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    class="mt-1"
                    type="checkbox"
                    name="checkbox"
                    value="PAID_MANUAL"
                    v-model="statusPicked"
                />&nbsp; PAID MANUAL
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    class="mt-1"
                    type="checkbox"
                    name="checkbox"
                    value="INPUT_NO_REGISTRASI_HIS"
                    v-model="statusPicked"
                />&nbsp; INPUT NO REGISTRASI HIS
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    class="mt-1"
                    type="checkbox"
                    name="checkbox"
                    value="SET_PLAT_NUMBER"
                    v-model="statusPicked"
                />&nbsp; SET PLAT NUMBER
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    class="mt-1"
                    type="checkbox"
                    name="checkbox"
                    value="SAMPLE_LAB_DIAMBIL"
                    v-model="statusPicked"
                />&nbsp; SAMPLE LAB DIAMBIL
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    class="mt-1"
                    type="checkbox"
                    name="checkbox"
                    value="SAMPLE_LAB_DIKIRIM"
                    v-model="statusPicked"
                />&nbsp; SAMPLE LAB DIKIRIM
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    class="mt-1"
                    type="checkbox"
                    name="checkbox"
                    value="CANCEL"
                    v-model="statusPicked"
                />&nbsp; CANCEL
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    class="mt-1"
                    type="checkbox"
                    name="checkbox"
                    value="COMPLETE"
                    v-model="statusPicked"
                />&nbsp; COMPLETE
            </label>
            <label class="dropdown-item d-block pointer"
                ><input
                    @change="changedData"
                    class="mt-1"
                    type="checkbox"
                    name="checkbox"
                    value="EXPIRED_WORKFLOW"
                    v-model="statusPicked"
                />&nbsp; EXPIRED WORKFLOW
            </label>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: { type: Array }
    },
    data() {
        return {
            statusPicked: [],
            checkUncheck: false,
            prevStatus: []
        };
    },
    watch: {
        data(val) {
            this.statusPicked = val ? val : [];
        }
    },
    methods: {
        changedData() {
            this.$emit("update:changedValue", this.statusPicked);
        },
        checkAllStatus() {
            if (this.checkUncheck) {
                this.prevStatus = this.statusPicked;
                this.statusPicked = [
                    "REGISTRASI_CREATED",
                    "GENERATE_QR_ONLINE",
                    "GENERATE_QR_OFFLINE",
                    "WAITING_PAYMENT_ONLINE",
                    "PAYMENT_EXPIRED",
                    "PAYMENT_CHANGE",
                    "CHANGE_PAYMENT_TO_ONLINE",
                    "CHANGE_PAYMENT_TO_OFFLINE",
                    "UPDATE_PATIENT",
                    "UPDATE_PAKET_PATIENT",
                    "PAID",
                    "PAID_MANUAL",
                    "PATIENT_DATANG",
                    "SET_PLAT_NUMBER",
                    "INPUT_NO_REGISTRASI_HIS",
                    "SAMPLE_LAB_DIAMBIL",
                    "SAMPLE_LAB_DIKIRIM",
                    "CANCEL",
                    "COMPLETE",
                    "EXPIRED_WORKFLOW"
                ];
            } else {
                this.statusPicked = this.prevStatus;
            }
            this.$emit("update:changedValue", this.statusPicked);
        }
    },
    created() {
        this.statusPicked = this.data;
    }
};
</script>

<style></style>
