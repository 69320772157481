<template>
    <div>
        <div v-if="!kasirViewVue" class="text-center">
            <template v-if="petugasLabVue">
                <button
                    v-if="
                        rowData.statusLab !== 'SAMPLE_LAB_DIAMBIL' &&
                            checkPermission('DriveThruResource.POST.TakeSample')
                    "
                    @click="changeStatus"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="Ubah Status"
                >
                    <i class="fas fa-vial"></i>
                </button>
                <button
                    v-if="
                        !swabber &&
                            checkPermission(
                                'DriveThruResource.POST.UploadTestResult'
                            )
                    "
                    @click="buttonModal"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="Upload"
                >
                    <i class="fa fa-upload" aria-hidden="true"></i>
                </button>
                <button
                    v-if="
                        rowData.resultLabId &&
                            !swabber &&
                            checkPermission('DriveThruResource.GET.TestResult')
                    "
                    @click="seeResult"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="Lihat Hasil"
                >
                    <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
                <button
                    v-if="
                        !swabber &&
                            checkPermission('DriveThruResource.GET.TestResult')
                    "
                    @click="seeEpidemiologi"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="Lihat Epidemiologi"
                >
                    <i class="fas fa-notes-medical"></i>
                </button>
            </template>
        </div>
        <div v-if="kasirViewVue" class="text-center">
            <template>
                <button
                    v-if="
                        checkPermission('DriveThruResource.GET.DetailBooking')
                    "
                    @click="openBookingDetail"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="View"
                >
                    <i class="la la-eye"></i>
                </button>
                <button
                    v-if="
                        checkPermission('DriveThruResource.POST.CancelBooking')
                    "
                    @click="cancelBooking"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="View"
                >
                    <i class="la la-trash"></i>
                </button>
            </template>
        </div>
    </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const DriveThruRepository = RepositoryFactory.get("driveThru");
import BlockUI from "../../_js/BlockUI";

export default {
    props: {
        rowData: { type: Object },
        petugasLabVue: { type: Object },
        cellData: { type: Array },
        isUpload: { type: Boolean },
        isDone: { type: Boolean },
        kasirViewVue: { type: Object },
        swabber: { type: Boolean }
    },
    data() {
        return {
            blockUi: new BlockUI()
        };
    },
    methods: {
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        },
        seeResult() {
            Swal.fire({
                title: `Hasil Test ${this.rowData.paketName} untuk Pasien ${
                    this.rowData.fullName
                } dengan Order Id #${
                    this.rowData.orderId
                } dan Pemeriksaan Id #${this.rowData.pemeriksaanId}`,
                html: `<div class="text-center">
               <p> ${
                   this.rowData.hasil ? this.rowData.hasil : "HASIL TIDAK ADA"
               } </p>
               <a href="${
                   this.rowData.fileDownloadUri
                       ? this.rowData.fileDownloadUri
                       : this.rowData.linkExternal
               }"> ${
                    this.rowData.fileDownloadUri
                        ? this.rowData.fileDownloadUri
                        : this.rowData.linkExternal
                } </a>
            </div>`,
                showCloseButton: true,
                showConfirmButton: false,
                focusConfirm: false
            });
        },
        async getEpidemiologi(testId) {
            try {
                const resp = await DriveThruRepository.getEpidemiologi({
                    testId
                });
                return resp;
            } catch (error) {
                console.log(error);
            }
        },
        async seeEpidemiologi() {
            const testId = this.rowData.pemeriksaanId;
            try {
                this.blockUi.blockPage();
                const {
                    klinis,
                    tujuan,
                    error,
                    tglKedatangan
                } = await this.getEpidemiologi(testId);

                this.blockUi.unblockPage();

                if (!error) {
                    const data = {
                        ...klinis,
                        tujuanPemeriksaan: tujuan,
                        tglKedatangan: tglKedatangan
                    };
                    let html = `<div class="text-center epidemiologi-text">`;
                    html += `
                    <div class="row">
                     <label class="col-5 col-form-label">Tgl Kunjungan</label>
                     <span style="line-height: 35px;" class="col-1">:</span>
                     <label class="col-6 col-form-label">${moment(
                         data.tglKedatangan
                     ).format("DD-MMM-YYYY")}</label>
                    </div>
                    `;

                    html += `
                    <div class="row">
                     <label class="col-5 col-form-label">Nama Pasien</label>
                     <span style="line-height: 35px;" class="col-1">:</span>
                        <label class="col-6 col-form-label">${
                            this.rowData.fullName
                        }</label>
                    </div>
                    `;
                    html += `
                    <div class="row">
                     <label class="col-5 col-form-label">Jenis Pemeriksaan</label>
                     <span style="line-height: 35px;" class="col-1">:</span>
                     <label class="col-6 col-form-label">${
                         this.rowData.paketName
                     }</label>

                    
                      </div>

                    `;

                    html += `
                    <div class="row">
                     <label class="col-5 col-form-label">Tanggal Awal Gejala</label>
                     <span style="line-height: 35px;" class="col-1">:</span>
                     <label class="col-6 col-form-label">${
                         data.tglAwalGejala ? data.tglAwalGejala : "-"
                     }</label>
                      </div>

                    `;
                    html += `
                    <div class="row">
                     <label class="col-5 col-form-label">Detail Gejala</label>
                     <span style="line-height: 35px;" class="col-1">:</span>
                     <label class="col-6 col-form-label">${
                         data.gejalaDetail ? data.gejalaDetail : "-"
                     }</label>

                     
                      </div>

                    `;
                    html += `
                    <div class="row">
                     <label class="col-5 col-form-label">Riwayat Kontak</label>
                     <span style="line-height: 35px;" class="col-1">:</span>
                     <label class="col-6 col-form-label">${
                         data.riwayatKontak ? data.riwayatKontak : "-"
                     }</label>
                     </div>

                    `;
                    html += `
                    <div class="row">
                     <label class="col-5 col-form-label">Detail Riwayat Kontak</label>
                     <span style="line-height: 35px;" class="col-1">:</span>
                     <label class="col-6 col-form-label">${
                         data.riwayatKontakDetail
                             ? data.riwayatKontakDetail
                             : "-"
                     }</label>

                      </div>
                    `;
                    html += `
                    <div class="row">
                     <label class="col-5 col-form-label">Detail Penyakit Penyerta</label>
                     <span style="line-height: 35px;" class="col-1">:</span>
                     <label class="col-6 col-form-label">${
                         data.penyakitPenyertaDetail
                             ? data.penyakitPenyertaDetail
                             : "-"
                     }</label>

                     </div>
                    `;
                    html += `
                    <div class="row">
                     <label class="col-5 col-form-label">Riwayat Perjalanan</label>
                     <span style="line-height: 35px;" class="col-1">:</span>
                     <label class="col-6 col-form-label">${
                         data.riwayatPerjalanan
                     }</label>
                    </div>
                    `;
                    html += `
                    <div class="row">
                     <label class="col-5 col-form-label">Tujuan Pemeriksaan</label>
                     <span style="line-height: 35px;" class="col-1">:</span>
                     <label class="col-6 col-form-label">${
                         data.tujuanPemeriksaan
                     }</label>
                    </div>
                    `;

                    html += ` </div>`;
                    Swal.fire({
                        title: `<strong style="font-size: large;">Order Id #${
                            this.rowData.orderId
                        }</strong>`,
                        html: html,
                        showCloseButton: true,
                        showConfirmButton: true,
                        focusConfirm: false,
                        confirmButtonText: "Tutup"
                    });
                } else {
                    swal.fire(error.message, "error");
                }
            } catch (error) {
                console.log(error);
            }
        },
        changeStatus() {
            this.petugasLabVue.changeBookingStatus(this.rowData);
        },
        buttonModal() {
            this.petugasLabVue.showUploadModal(this.rowData);
        },
        openBookingDetail() {
            this.kasirViewVue.openDetail(this.rowData.orderId);
        },
        cancelBooking() {
            this.kasirViewVue.cancelBookingan(
                this.rowData.orderId,
                this.rowData.encryptedId,
                this.rowData.testPemeriksaan
            );
        }
    }
};
</script>

<style></style>
