var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.kasirViewVue)?_c('div',{staticClass:"text-center"},[(_vm.petugasLabVue)?[(
                    _vm.rowData.statusLab !== 'SAMPLE_LAB_DIAMBIL' &&
                        _vm.checkPermission('DriveThruResource.POST.TakeSample')
                )?_c('button',{staticClass:"btn btn-sm btn-clean btn-icon btn-icon-md",attrs:{"title":"Ubah Status"},on:{"click":_vm.changeStatus}},[_c('i',{staticClass:"fas fa-vial"})]):_vm._e(),(
                    !_vm.swabber &&
                        _vm.checkPermission(
                            'DriveThruResource.POST.UploadTestResult'
                        )
                )?_c('button',{staticClass:"btn btn-sm btn-clean btn-icon btn-icon-md",attrs:{"title":"Upload"},on:{"click":_vm.buttonModal}},[_c('i',{staticClass:"fa fa-upload",attrs:{"aria-hidden":"true"}})]):_vm._e(),(
                    _vm.rowData.resultLabId &&
                        !_vm.swabber &&
                        _vm.checkPermission('DriveThruResource.GET.TestResult')
                )?_c('button',{staticClass:"btn btn-sm btn-clean btn-icon btn-icon-md",attrs:{"title":"Lihat Hasil"},on:{"click":_vm.seeResult}},[_c('i',{staticClass:"fa fa-eye",attrs:{"aria-hidden":"true"}})]):_vm._e(),(
                    !_vm.swabber &&
                        _vm.checkPermission('DriveThruResource.GET.TestResult')
                )?_c('button',{staticClass:"btn btn-sm btn-clean btn-icon btn-icon-md",attrs:{"title":"Lihat Epidemiologi"},on:{"click":_vm.seeEpidemiologi}},[_c('i',{staticClass:"fas fa-notes-medical"})]):_vm._e()]:_vm._e()],2):_vm._e(),(_vm.kasirViewVue)?_c('div',{staticClass:"text-center"},[[(
                    _vm.checkPermission('DriveThruResource.GET.DetailBooking')
                )?_c('button',{staticClass:"btn btn-sm btn-clean btn-icon btn-icon-md",attrs:{"title":"View"},on:{"click":_vm.openBookingDetail}},[_c('i',{staticClass:"la la-eye"})]):_vm._e(),(
                    _vm.checkPermission('DriveThruResource.POST.CancelBooking')
                )?_c('button',{staticClass:"btn btn-sm btn-clean btn-icon btn-icon-md",attrs:{"title":"View"},on:{"click":_vm.cancelBooking}},[_c('i',{staticClass:"la la-trash"})]):_vm._e()]],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }