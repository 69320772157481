<template>
    <input
        type="text"
        class="form-control input-datepicker"
        v-bind:id="idDatePicker"
        v-bind:placeholder="placeHolder"
        :value="dateValue"
        autocomplete="off"
    />
</template>

<script>
export default {
    data() {
        return {
            dataResponse: null,
            dateValue: null
        };
    },
    props: {
        idDatePicker: { type: String },
        pickerValue: {},
        placeHolder: { type: String },
        periodeAwal: { type: String, default: "" },
        periodeAkhir: { type: String, default: "" },
        reset: { type: Boolean, default: false },
        newDate: { type: String, default: "" },
        swabber: { type: Boolean, default: false },
        minDate: { type: String },
        dateRangeContainerId: {type: String}
    },
    watch: {
        reset: function(val) {
            if (val) {
                this.dateValue = null;
                this.$emit("update:reset", false);
            }
        },
        newDate: function(val) {
            if (val) {
                let dates = val.split(" - ");
                let start = dates[0];
                let end = dates[1];
                if (start == "null" && end == "null") {
                    this.dateValue = null;
                    this.$emit("update:newDate", "");
                } else {
                    this.dateValue = `${moment(start).format(
                        "DD-MM-YYYY"
                    )} - ${moment(end).format("DD-MM-YYYY")}`;
                }
            }
        }
    },
    methods: {
        getDatePicker: function() {
            var vx = this;
            // minDate
            let datePickerOptions = {
                autoUpdateInput: false,
                buttonClasses: "btn btn-sm",
                applyClass: "btn-primary",
                cancelClass: "btn-secondary",
            };
            if (this.dateRangeContainerId){
                datePickerOptions.parentEl = "#" + this.dateRangeContainerId
            }
            if (this.minDate) {
                datePickerOptions.minDate = this.minDate;
            }
            $("#" + this.idDatePicker)
                .daterangepicker(datePickerOptions, (startDate, endDate) => {
                    vx.dateValue =
                        startDate.format("DD/MMM/YYYY") +
                        " - " +
                        endDate.format("DD/MMM/YYYY");
                    var dateStart = moment(startDate, "DD/MM/YYYY").format(
                        "YYYY-MM-DD"
                    );
                    var dateEnd = moment(endDate, "DD/MM/YYYY").format(
                        "YYYY-MM-DD"
                    );

                    vx.$emit("update:changedValueStart", dateStart);
                    vx.$emit("update:changedValueEnd", dateEnd);
                })
                .on("change", () => {
                    var val = $("#" + this.idDatePicker).val();
                    if (val.length == 0) {
                        vx.$emit("update:changedValueStart", "");
                        vx.$emit("update:changedValueEnd", "");
                    } else if (val.length != 23) {
                        var temp =
                            vx.dateValue.length == 23 ? vx.dateValue : "";
                        $("#" + this.idDatePicker).val(temp);
                    }
                });
            if (this.periodeAwal != "" && this.periodeAkhir != "") {
                $("#" + this.idDatePicker).val(
                    moment(vx.periodeAwal, "YYYY-MM-DD").format("DD/MM/YYYY") +
                        " - " +
                        moment(vx.periodeAkhir, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                        )
                );
                $("#" + this.idDatePicker)
                    .data("daterangepicker")
                    .setStartDate(
                        moment(vx.periodeAwal, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                        )
                    );
                $("#" + this.idDatePicker)
                    .data("daterangepicker")
                    .setEndDate(
                        moment(vx.periodeAkhir, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                        )
                    );
            }
        },
        clearData: function() {
            this.dateValue = null;
        }
    },
    mounted() {
        this.getDatePicker();
    }
};
</script>
